.wepa-general-data {
  &__bookings-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($v2-spacers, 16);
  }

  &__bookings-table {
    margin-top: map-get($v2-spacers, 16);
  }
}

// Styling for the new broker connections CTA
.broker-connections-cta {
  padding: 1.5rem;
  border-radius: 8px;
  background-color: rgba(65, 137, 230, 0.08);
  border: 1px solid rgba(65, 137, 230, 0.2);
  margin: 1rem 0;
  min-height: 300px;
  width: 100%;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 250px;
    position: relative;

    .button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
